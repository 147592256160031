import React     from "react";

import {
    Grid,
} from "@material-ui/core";
import PageTitle from "../PageTitle/PageTitle";

import SingleGridCard from "../SingleGridCard/SingleGridCard";
import Skeleton from "@material-ui/lab/Skeleton";

import {useTranslation} from "react-i18next";

const GiftCart = ({title, homeData}) => {
    const {home, loading} = homeData;
    const {t} = useTranslation()

    return (
        <div>
            {title && <PageTitle title={t("best_sellers")} variant="h2"/>}
            <Grid className="" container spacing={2}>
                {loading ? (
                    <Grid className={"mt-5 mb-5"} container spacing={4}>
                        {[1, 2, 3, 4].map((item, i) => (
                            <Grid item xs={12} sm={6} md={3} key={i}>
                                <Skeleton variant="rect" width={278} height={347}/>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    home?.data?.best_selling_products.map((item, i) => (
                        <SingleGridCard key={i} item={item}/>
                    ))
                )}
            </Grid>
        </div>
    );
};

export default GiftCart;
