import React from "react";
import PageTitle from "../PageTitle/PageTitle";
import { Grid, Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import p1 from "../../Assets/images/choose-game-gift-card@2x.png";
import p2 from "../../Assets/images/gift-card-payment-process@2x.png";
import p3 from "../../Assets/images/fastest-online-email-delivery@2x.png";

import {useTranslation} from "react-i18next";

const Work = () => {
const {t} = useTranslation()

  return (
    <div>
      <PageTitle title={t("how_it_works")} />
      <Grid container spacing={2}>
        <Grid item md={4} sm={6} xs={12}>
          <Box className='Work_Section Cart_Game_head'>
            <img src={p1} alt='' />
            <Typography variant='h4' component='h3'>
                {t("Choose_a_Game/Gift_Card")}
            </Typography>
            <Typography variant='h5' component='p'>
                {t("Choose_a_Game/Gift_Card_p")}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Box className='Work_Section'>
            <img src={p2} alt='' />
            <Typography variant='h4' component='h3'>
                {t("Payment_&_Approval")}
            </Typography>
            <Typography variant='h5' component='p'>
                {t("Payment_&_Approval_p")}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Box className='Work_Section'>
            <img src={p3} alt='' />
            <Typography variant='h4' component='h3'>
                {t("Fastest_Online_Email_Delivery")}
            </Typography>
            <Typography variant='h5' component='p'>
                {t("Fastest_Online_Email_Delivery_p")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Work;
