import {
  auth,
  register,
  forgotPassword,
  verifyConst,
  auth_otp,
} from "../constant/auth-constant";
import Axios from "axios";
import { _http } from "../../helper/httpServices";
import { ERROR, SUCCESS } from "../../feature/alerts";
import AuthCode from "react-auth-code-input";
import {history} from "../../Router/history";
import i18n from "i18next";

const url = process.env.REACT_APP_BASE_URL;

// user Autg
export const userAuth =
  (email, verify_code = -1) =>
  async (dispatch) => {
    console.log("from user auth");
    dispatch({
      type: auth.USER_AUTH_REQUEST,
      payload: { email },
    });
    const emailFormData = new FormData();
    if (verify_code === -1) {
      emailFormData.append("email", email);
      emailFormData.append("with_send_code", 1);

      await Axios({
        method: "post",
        url: `${url}login`,
        data: emailFormData,
        headers: { "Content-Type": "multipart/form-data" , 'Accept-Language': i18n.language },
      })
        .then((data) => {
          console.log(data)
          dispatch({ type: auth.USER_AUTH_SUCCESS_ONE, payload: data.data.message });

          if (data.status === 200) {
            let message = 'کد ارسال شد'
            if(data?.data?.message){
              message = data?.data?.message
            }
            SUCCESS(message)
            // console.log(data)
          } else if (data.status !== 200) {
            let message = "اطلاعات وارد شده اشتباه می باشد"

            ERROR(message);
            // console.log(data)
          }
        })
        .catch((error) => {

          dispatch({
            type: auth.USER_AUTH_FAIL,
            payload: error?.response?.data?.errors,
          });
        });
    } else {
      await Axios.post(url + `login`, {
        email,
        verify_code,
        with_send_code: 0,
      },       
      { 
        headers: { 'Accept-Language': i18n.language }
      },
      )
        .then((data) => {


          if (data.status === 200) {
              dispatch({ type: auth.USER_AUTH_SUCCESS_TWO,payload:data.data.data.access_token });
            localStorage.setItem(
              "userInfo",
              JSON.stringify(data.data.data.access_token)
            );
            // window.location.href = "/";
            history.goBack()

            SUCCESS("کاربر با موفقیت وارد شد");
          } else if (data.status !== 200) {
            ERROR("اطلاعات وارد شده اشتباه می باشد");
          }
        })
        .catch((error) => {
          console.log(error?.response?.data?.errors, "REdux user Auth");
          let message;
          if (error?.response?.data?.message)
            message = error?.response?.data?.message;
          else message = error?.message;
          ERROR(message);
          dispatch({
            type: auth.USER_AUTH_FAIL,
            payload: error?.response?.data?.errors,
          });
        });
    }
  };

//OTP

export const userAuthOTP =
  (data, verify_code = -1) =>
  async (dispatch) => {
    dispatch({
      type: auth_otp.USER_AUTH_OTP_REQUEST,
      payload: { mobile:data.mobile },
    });
    let mobileFormData = new FormData();

    if (verify_code === -1) {
      mobileFormData.append("mobile", data.mobile);
      mobileFormData.append("country_code", data.country_code);
      mobileFormData.append("with_send_code", 1);

      await Axios({
        method: "post",
        url: `${url}loginOtp`,
        data: mobileFormData,
        headers: { 'Accept-Language': i18n.language },
      })
        .then((data) => {
          if (data.status === 200) {
          dispatch({ type: auth_otp.USER_AUTH_OTP_SUCCESS_ONE, payload: data.data.message  });

          }
          // else if (data.status !== 200) {
          //   ERROR("اطلاعات وارد شده اشتباه می باشد");
          // }
        })
        .catch((error) => {
          // console.log(error?.response?.data?.errors, "REdux user Auth");
          // let message;
          // if (error?.response?.data?.message)
          //   message = error?.response?.data?.message;
          // else message = error?.message;
          // ERROR(message);
          dispatch({
            type: auth_otp.USER_AUTH_OTP_FAIL,
            payload: error?.response?.data?.errors,
          });
        });
    } else {
      await Axios.post(url + `loginOtp`, {
        mobile:data.mobile,
        country_code:data.country_code,
        with_send_code: 0,
        verify_code,
      },
      { 
        headers: { 'Accept-Language': i18n.language }
      },)
        .then((data) => {

          dispatch({ type: auth_otp.USER_AUTH_OTP_SUCCESS_TWO,payload:data.data.data.access_token});
          if (data.status === 200) {
            localStorage.setItem(
              "userInfo",
              JSON.stringify(data.data.data.access_token)
            );
            // window.location.href = "/";
            history.goBack()
            // SUCCESS("اطلاعت کاربر با موفقیت ثبت شد");
          } else if (data.status !== 200) {
            // ERROR("اطلاعات وارد شده اشتباه می باشد");
          }
        })
        .catch((error) => {
          // console.log(error?.response?.data?.errors, "REdux user Auth");
          // let message;
          // if (error?.response?.data?.message)
          //   message = error?.response?.data?.message;
          // else message = error?.message;
          // ERROR(message);
          dispatch({
            type: auth_otp.USER_AUTH_OTP_FAIL,
            payload: error?.response?.data?.errors,
          });
        });
    }
  };

///user Register
export const userRegister = (data) => async (dispatch) => {
  dispatch({
    type: register.USER_REGISTER_REQUEST,
    payload: data,
  });
  await Axios.post(url + `register`, data,{ 
    headers: { 'Accept-Language': i18n.language }
  },)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: register.USER_REGISTER_SUCCESS,
          payload: res,
        });
        SUCCESS(res?.data?.message);
      } else {
        console.log('from register error')
        ERROR(res?.response?.data?.message);
        dispatch({ type: register.USER_REGISTER_FAIL, payload: res });
      }
    })
    .catch((error) => {

      // let message;
      // if (error?.response?.data?.errors.email ||error?.response?.data?.errors.mobile)
      //   message = `${error?.response?.data?.errors.email[0]}
      //   ${error?.response?.data?.errors?.mobile?.[0]? error?.response?.data?.errors?.mobile?.[0]:""}`;
      //
      //  else message = error?.message;
      // console.log(message)
      // ERROR(message);
      dispatch({
        type: register.USER_REGISTER_FAIL,
        payload: error?.response?.data?.errors,
      });
    });
  dispatch({
    type: verifyConst.GET_USER_VERIFY_DATA,
    payload: data,
  });
};

///user Verify Register
export const userVerifyRegister = (data) => async (dispatch) => {
  dispatch({
    type: verifyConst.USER_VERIFY_REGISTER_REQUEST,
    payload: { data },
  });
  await Axios.post(url + `register`, data,{ 
    headers: { 'Accept-Language': i18n.language }
  },)
    .then((res) => {
      if (res.status === 200) {
        localStorage.setItem(
          "userInfo",
          JSON.stringify(res.data.data.access_token)
        );
        dispatch({
          type: verifyConst.USER_VERIFY_REGISTER_SUCCESS,
          payload: data,
        });
        SUCCESS(res?.data?.message || "ثبت نام موفقیت آمیز بود");
        window.location.href = "/profile";
      } else if (res.status === 422) {
        dispatch({ type: verifyConst.USER_VERIFY_REGISTER_FAIL, payload: res });
        ERROR(res?.response?.data?.message);
      }
    })
    .catch((error) => {
      let message;
      if (error?.response?.data?.message)
        message = error?.response?.data?.message;
      else message = error?.message;
      ERROR(message);
      dispatch({
        type: verifyConst.USER_VERIFY_REGISTER_FAIL,
        payload: error.response?.data?.errors,
      });
    });
};

///forgtaPassword
export const userForgotPassword = (email) => async (dispatch) => {
  dispatch({
    type: forgotPassword.USER_FORGOTPASS_REQUSET,
    payload: email,
  });
  await Axios.post(url + `forgot/password`, email,{ 
    headers: {'Accept-Language': i18n.language }
  },)
    .then((res) => {
      dispatch({
        type: forgotPassword.USER_FORGOTPASS_SUCCESS,
        payload: res,
      });
      if (res.status === 200) {
        SUCCESS("یک ایمیل حاوی رمیز عبور برای شما ارسال شد");
        window.location.href = "/verify";
      } else {
        ERROR(res.data.message);
      }
    })
    .catch((err) => {
      let message;
      if (err?.response.data.message) message = err.response.data.message;
      ERROR(message);
      // dispatch({ type: forgotPassword.USER_FORGOTPASS_FAIL, payload: message });
    });
};
/// user Log out logout
export function logout() {
  return (dispatch) => {
    dispatch(request());
    return _http("/logout", { method: "get" })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("cardList");
          history.push('/login')

          dispatch({
            type: auth.USER_SIGN_OUT,
            data: res.data,
          });

          // window.location.href = "/";
        }
      })
      .catch((e) => {
        if (e?.response?.data) ERROR(e?.response?.data?.message);
        // else ERROR(e?.message);
      });

    function request(res) {
      return { type: auth.USER_SIGN_OUT, res };
    }
  };
}

//verify user
export const verifyUser = (email, verify_code) => async (dispatch) => {
  dispatch({ type: verifyConst.USER_verifyConst_REQUSET, email, verify_code });
  await Axios.post(url + "check/verify/code", { email, verify_code },{ 
    headers: { 'Accept-Language': i18n.language }
  },)
    .then((data) => {
      if (data.status === 200) {
        dispatch({ type: verifyConst.USER_verifyConst_SUCCESS, payload: data });
        SUCCESS("اطلاعت کاربر با موفقیت ثبت شد");
        localStorage.setItem(
          "userInfo",
          JSON.stringify(data.data.data.access_token)
        );
        window.location.href = "/";
      } else if (data.status !== 200) {
      }
    })
    .catch((err) => {
      let message;
      if (err?.response.data.message) message = err.response.data.message;
      ERROR(message);
      // dispatch({ type: verifyConst.USER_verifyConst_FAIL, payload: err });
    });
};
