import React from "react";
import {useSelector} from "react-redux";
import {Container, Typography, List, ListItem, Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Instagram, Facebook} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import LanguageSelect from "../../components/LanguageSelect/LanguageSelect";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const Footer = () => {
    const getCategorie = useSelector((state) => state.getCategorie);
    const {categori, loading} = getCategorie;
    const {t} = useTranslation()
    const handleBacK = () => {
        window.scrollTo(0, 0)
    }
    return (
        <div className="footer_container">
            <div className="top_footer">
                <div onClick={handleBacK} className={"d-flex justify-content-center text-white "}
                     style={{cursor: "pointer"}}>
                    <ArrowUpwardIcon className={"text-white"} style={{fontSize: "18px"}}/>
                </div>
                <Container maxWidth="lg">
                    <div className="Footer_align">
                        <div className="choose_lang d-flex flex-column align-items-center">
                            <p className='mb-2'>{t("Choose_Your_Language_Worldwide_Delivery")} </p>
                            <LanguageSelect/>
                        </div>
                        <div className="footer_Delivery">
                            <Typography variant="h4" component="h3" style={{textAlign: "start"}}>
                                {t("Why_GameCardDelivery")}{t("question_sign")}
                                <List className="footer_list">
                                    <ListItem>{t("Buy_Online")}</ListItem>
                                    <ListItem>{t("instant_delivery")}</ListItem>
                                    <ListItem>{t("Easy_to_Use")}</ListItem>
                                </List>
                            </Typography>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="footerBg ">
                <Container maxWidth="lg">
                    <Grid container spacing={4}>
                        <Grid className="Footer_list_menu" item md={4} sm={6} xs={12}>
                            <Typography
                                component="h3"
                                variant="h4"
                                className={"Suport_Team"}>
                                {t("About")}
                            </Typography>
                            <Typography component="P" variant="h4" className={"About_Gift"}>
                                {t("About_GIFT_SHOP")}
                            </Typography>
                        </Grid>
                        <Grid className="Footer_list_menu" item md={4} sm={6} xs={12}>
                            <Typography component="h3" variant="h4" className={"Suport_Team"}>
                                {t("Product_Categories")}              </Typography>
                            <Typography component="h3" variant="h4">
                                {t("Product_Categories_p")}           </Typography>
                            <List>
                                {categori?.categories?.slice(0, 6)?.map((item, i) => (
                                    <ListItem key={`Footter_${i}`}>
                                        <Link to={`/categories/${item.slug}`}>
                                            {item.name}
                                        </Link>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid className="Footer_list_menu" item md={4} sm={6} xs={12}>
                            <Typography className="Suport_Team" component="h3" variant="h4">
                                {t("Contact_Our_Support_Team")}
                            </Typography>
                            <List className={"item_footer"}>
                                <ListItem>
                                    <Link className="Support_link">info@yahoo.com</Link>
                                </ListItem>
                                <ListItem>
                                    <Typography
                                        className="contact_phone"
                                        component="p"
                                        variant="h4"
                                    >
                                        {" "}
                                        09120000000
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography
                                        className="new_G"
                                        component="p"
                                        variant="h4"
                                    >
                                        {t("See_What’s_New_in_the_Gaming_World!")}
                                    </Typography>
                                </ListItem>
                                <div className="Social_footer">
                                    <Link className="FaceBook">
                                        <Facebook/>
                                    </Link>
                                    <Link className="Insta">
                                        <Instagram/>
                                    </Link>
                                </div>
                            </List>
                        </Grid>
                    </Grid>
                </Container>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319">
                    <path fill="#ca1d1d" fill-opacity="1"
                          d="M0,96L60,85.3C120,75,240,53,360,58.7C480,64,600,96,720,133.3C840,171,960,213,1080,218.7C1200,224,1320,192,1380,176L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
                </svg>
            </div>
        </div>
    );
};

export default Footer;
