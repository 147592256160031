import React, { useEffect, useRef, useState } from "react";
import { Container, IconButton, Badge } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ShoppingCart } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { gteAllCategories } from "../../redux/actions/categori-action";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Search from "../../components/Search/Search";

import CategoryMenu from "../nav/CategoryMenu";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 20,
    border: `2px solid  #9e0b0f`,
    padding: "0 4px",
  },
}))(Badge);
const NavHeader = () => {
  // const [data, setData] = useState();
  const getCategorie = useSelector((state) => state.getCategorie);

  const cardList = useSelector((state) => state.cartData.cardList);
  const [showSearch, setShowSearch] = useState(false);

  const searchContainerRef = useRef(null);

  const { categori, loading } = getCategorie;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(gteAllCategories());
  }, [dispatch]);

  return (
    <div className="Nav_HEader">
      <Container maxWidth="lg">
        <div className="NAV_Align">
          <nav className="">
            <ol className="NAV_olText">
            <li  className="dropdown">
                  <Link to={`/`}>
                    <span className="dropbtn">صفحه اصلی</span>
                  </Link>
                </li>
              <li>
                <CategoryMenu categori={categori} />
              </li>
              {/* <li className="dropdown">
                <span className="dropbtn">{t("gift_cards")}</span>
                <div className="dropdown-content">
                  <Link to="#">کارت هدیه گوگل</Link>
                  <Link to="#">کارت هدیه xbox</Link>
                  <Link to="#">کارت های بازی Karma Koin</Link>
                </div>
              </li> */}
              {/* <li className="dropdown">
                                <Link to="#" className="dropbtn">
                                    {t("playstation")}
                                </Link>
                            </li>
                            <li className="dropdown">
                                <Link className="dropbtn" to="#">
                                    {t("google_play")}
                                </Link>
                            </li>
                            <li className="dropdown">
                                <Link to="#" className="dropbtn">
                                    {t("steam")}
                                </Link>
                            </li> */}
              {/* <li className="dropdown">
                <span className="dropbtn">{t("apple/itunes")}</span>
                <div className="dropdown-content">
                  <Link to="#">کارت هدیه اپل 500</Link>
                  <Link to="#">کارت هدیه اپل 200</Link>
                  <Link to="#">کارت هدیه اپل 100</Link>
                  <Link to="#">کارت هدیه اپل 50</Link>
                  <Link to="#">کارت هدیه اپل 10</Link>
                </div>
              </li> */}
              
              {categori?.pages?.map((page, i) => (
                <li key={i} className="dropdown">
                  <Link to={`/page/${page.slug}`}>
                    <span className="dropbtn">{page.title}</span>
                  </Link>
                </li>
              ))}
            </ol>
          </nav>
          <div className="left-side-nav">
          <div className="Nav_search_Wrapper">
            {/*<Search/>*/}
            <Search setShowSearch={setShowSearch} />
          </div>
          <div className="Shop_Cart_bg">
            <IconButton
              aria-label="cart"
              className="Shop_Cart"
              component={Link}
              to={cardList ? "/checkout" : "/"}
            >
              <StyledBadge
                badgeContent={cardList ? cardList?.length : 0}
                color="error"
              >
                <ShoppingCart className="" />
              </StyledBadge>
            </IconButton>
          </div>
          </div>
        
        </div>
      </Container>
    </div>
  );
};

export default NavHeader;
