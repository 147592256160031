import React from "react";
import {Box, Container} from "@material-ui/core";
import {Link} from "react-router-dom";
import {HOME, CEHCkOUT} from "../../constant/Router";
import {useSelector} from "react-redux";
import LanguageSelect from "../../components/LanguageSelect/LanguageSelect";
import UserPofile from "./UserProfile";
import {useTranslation} from "react-i18next";


const Header = () => {
    const auth = useSelector((state) => state.auth);
    const {userInfo} = auth;
    const {t} = useTranslation();


    return (
        <div className="Top_Header">
            <Container maxWidth="lg">
                <div className="nav_align_item">
                    <h3 className="Logo_header px-3">
                        <Link to={HOME}>{t("logo_name")}</Link>
                    </h3>
                    <div className={"d-flex"}>
                        <LanguageSelect userInfo={userInfo} />
                        {userInfo ? (
                            <UserPofile/>
                        ) : (
                            <>
                                <Link className="Login_button ml-1 mr-2" to="/login">
                                    {t("login")}
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Header;
