import React from "react";
import MainRoute from "./App/Router/MainRoute";
import ThemeContext from "./App/context/ThemeContext";
import axios from "axios";
import {ERROR} from "./App/feature/alerts";
import {SnackbarProvider} from 'notistack';
import Slide from '@material-ui/core/Slide';

const App = (props) => {

    return (
        <ThemeContext>
            <SnackbarProvider
                onDurationChangeCapture={"300"}
                TransitionComponent={Slide}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                maxSnack={1}>

                <MainRoute/>
            </SnackbarProvider>
        </ThemeContext>
    );
};

export default App;


axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error === "Cannot read property 'status' of undefined") {
            ERROR("سایت در حال حاضر در دسترس نیست");
        } else if (401 === error.response.status) {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("cardList");
            if (process.browser) {
                window.location = "/";
            }
        } else if (403 === error.response.status) {
            ERROR(error?.response?.data?.message)
            if (process.browser) {
                window.location = "/";
            }
        } else {
            return Promise.reject(error);
        }
    }
);

