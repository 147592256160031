import React, {useEffect, useRef, useState} from "react";
import {Menu} from "@material-ui/icons";
import {Container, Badge, IconButton} from "@material-ui/core";
import {ShoppingCart} from "@material-ui/icons";
import {withStyles} from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import Search from "../../components/Search/Search";
import ModalMenu from "./ModalMenu";
import {Link} from "react-router-dom";
import {CEHCkOUT, HOME} from "../../constant/Router.js";
import {useSelector} from "react-redux";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import {useTranslation} from "react-i18next";

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 20,
        border: `2px solid  #9e0b0f`,
        padding: "0 4px",
    },
}))(Badge);

const MobileHeader = () => {
    const cardList = useSelector((state) => state.cartData.cardList);

    const [showSearch, setShowSearch] = useState(false)


    const searchContainerRef = useRef(null)

    const {t} = useTranslation()
    useEffect(() => {
        const onGlobalClick = (event) => {

            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setShowSearch(false)
            }
        };

        document.addEventListener("mousedown", onGlobalClick);

        return () => document.removeEventListener("mousedown", onGlobalClick);
    }, []);

    const handleSearchClick = () => {
        setShowSearch(true)
    }
    return (
        <div className="mobile_nav_header">
            {/*<Container maxWidth="lg">*/}
            <div className="nav_align_item  ">
                <h3 className="text-white mx-1">
                    <Link style={{color: "white"}} to={HOME}>
                        {t("logo_name")}
                    </Link>
                </h3>
                <div className="d-flex align-items-center">
                    <IconButton className='mr-auto' onClick={handleSearchClick}>
                        <SearchIcon style={{color: "#fff", fontSize: '20px'}}/>
                    </IconButton>
                    <IconButton
                        component={Link}
                        to={CEHCkOUT}
                        aria-label="cart"
                        className="Shop_Cart"
                    >
                        <StyledBadge
                            badgeContent={cardList ? cardList.length : 0}
                            color="error"
                        >
                            <ShoppingCart/>
                        </StyledBadge>
                    </IconButton>
                    <ModalMenu>
                        <Menu className="Menu_Shop_cart"/>
                    </ModalMenu>

                </div>
            </div>
            <div ref={searchContainerRef}
                 className={`mobileSearch_container mobileSearch_container_${showSearch ? `show` : ''} d-flex justify-content-between align-items-center`}>
                <Search setShowSearch={setShowSearch}/>
                <CancelPresentationIcon fontSize="large" color='primary' className='search_closeBtn'
                                        onClick={(e) => setShowSearch(false)}/>
            </div>
        </div>
    );
};

export default MobileHeader;
