import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";


const defulterTheme = createTheme({


  palette: {
    primary: {
      main: "#CA1D1D",
    },
    secondary: {
      main: "#262D36;",
    },
  },
  typography: {
    fontFamily: ["IRANSans"].join(","),
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 24,
  },
});

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const ThemeContext = ({ children }) => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={defulterTheme}>
      <StylesProvider jss={jss}>
        <div dir={`${t("direction")}`}>{children}</div>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default ThemeContext;
