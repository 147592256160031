import React from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@material-ui/core";
import { AccountCircle, ExpandMore, Lock, ShoppingCart } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/auth-actions";
import { useTranslation } from "react-i18next";
import LanguageModal from "./LangugeModal";


const useStyles = makeStyles({

    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
    paper: {
        background: "#1a1a1a",
        color: "white",
    },

    List_bg: {
        background: "#222",
        marginBottom: 3,
        height: 60,
        // padding: "0px 9px 6px 0",
        // marginTop: "-8px",
        padding: "0px 12px",
        flexDirection: "row-reverse"

    },
    other_List_bg: {
        background: "#222",
        height: 60,
        direction: "ltr",
        marginBottom: 1,
    },
    Log_Ico: {
        fontSize: " 2.3rem !important",
        margin: "0px 0 1px 4px",
        color: "#fff",
    },
    text: {
        color: "#fff",
        fontSize: 12,
        width: "100%",
        textTransform: "capitalize",
        "&:hover": {
            color: "#fff",
        },
    },
    dropdown_link: {
        textAlign: "right",
        fontSize: 12,
        marginRight: "auto",
        color: "#fff",
        display: "inline-block",
        direction: "ltr",
        textDecoration: "none",
        cursor: "pointer",
        textTransform: "capitalize",

        "&:hover": {
            color: "#fff",
        },
    },
    drawerAccordion: {
        backgroundColor: "#222",
        color: "white",
        direction: "ltr",
    },
    drawerAccordionDetails: {
        backgroundColor: "#333",
    },
});
const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 20,
        border: `2px solid  #9e0b0f`,
        padding: "0 4px",
        fontSize: "10px",
    },
}))(Badge);

export default function ModalMenu({ children, showSearch, searchContainerRef, setShowSearch }) {
    const getCategorie = useSelector((state) => state.getCategorie);
    const dispatch = useDispatch();
    const cardList = useSelector((state) => state.cartData.cardList);
    const userInfo = useSelector((state) => state.auth.userInfo);
    const { categori, loading } = getCategorie;

    const { t } = useTranslation()
    const history = useHistory();
    const dir = t("direction") === "rtl" ? "right" : "left";

    const useStyles = makeStyles({

        list: {
            width: 250,
        },
        fullList: {
            width: "auto",
        },
        paper: {
            background: "#1a1a1a",
            color: "white",
        },

        List_bg: {
            background: "#222",
            marginBottom: 3,
            height: 60,
            padding: "0px 12px",

        },
        other_List_bg: {
            background: "#222",
            height: 60,
            textAlign: t("direction") === "rtl" ? "right" : "left",
            direction: t("direction") === "rtl" ? "rtl" : "ltr",
            marginBottom: 1,
        },
        Log_Ico: {
            fontSize: " 2.3rem !important",
            margin: "0px 0 1px 4px",
            color: "#fff",
        },
        text: {
            color: "#fff",
            fontSize: 12,
            width: "100%",
            display: "block",
            textAlign: t("direction") === "rtl" ? "right" : "left",
            textTransform: "capitalize",
            "&:hover": {
                color: "#fff",
            },
        },
        dropdown_link: {
            textAlign: "right",
            fontSize: 12,
            marginRight: "auto",
            color: "#fff",
            display: "inline-block",
            direction: t("direction") === "rtl" ? "rtl" : "ltr",
            textDecoration: "none",
            cursor: "pointer",
            textTransform: "capitalize",

            "&:hover": {
                color: "#fff",
            },
        },
        drawerAccordion: {
            backgroundColor: "#222",
            color: "white",
            direction: t("direction") === "rtl" ? "rtl" : "ltr",
        },
        drawerAccordionDetails: {
            backgroundColor: "#333",
        },

        exitMenu: {
            textAlign: t("direction") === "rtl" ? "right" : "right",
            display: "block",
        },
        profiletxt: {
            color: "#fff",
            fontSize: 12,
            textAlign: t("direction") === "rtl" ? "right" : "right",
            display: "block",
        }
    });


    const handleLogoutClick = () => {
        dispatch(logout());
    };
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}

        >
            <List>
                {userInfo ?
                    <ListItem
                        onClick={toggleDrawer(anchor, false)}
                        className={classes.List_bg}
                    >
                        <ListItemIcon className='justify-content-center'>
                            <AccountCircle className={classes.Log_Ico} />
                        </ListItemIcon>
                        <ListItemText align="start">
                            <Link className={classes.profiletxt} to="/profile">
                                {t("profile")}
                            </Link>
                        </ListItemText>
                    </ListItem>

                    : null}
                <ListItem
                    onClick={toggleDrawer(anchor, false)}
                    className={classes.List_bg}
                >
                    <ListItemIcon onClick={() => history.push("/checkout")} className='justify-content-center'>
                        <IconButton component={Link} className="p-0"
                            aria-label="cart">
                            <StyledBadge
                                badgeContent={cardList ? cardList.length : 0}
                                color="error"
                                style={{ fontSize: "30px" }}
                            >
                                <ShoppingCart className={classes.Log_Ico} />
                            </StyledBadge>
                        </IconButton>
                    </ListItemIcon>
                    <ListItemText onClick={() => history.push("/checkout")} align="start">
                        <span className={classes.profiletxt}>
                            {t("cart")}
                        </span>
                    </ListItemText>
                </ListItem>
                <ListItem
                    onClick={toggleDrawer(anchor, false)}
                    className={classes.List_bg}
                >
                    <ListItemIcon className='justify-content-center'>
                        <Lock className={classes.Log_Ico} />
                    </ListItemIcon>
                    <ListItemText align="start">
                        {userInfo ? (
                            <span className={classes.exitMenu}
                                style={{ fontSize: "12px", cursor: "pointer" }}
                                onClick={handleLogoutClick}
                            >
                                {t("logout")}
                            </span>
                        ) : (
                            <Link className={classes.text} to="/login">
                                {t("login")}              </Link>
                        )}{" "}
                    </ListItemText>
                </ListItem>
            </List>
            <Divider />
            <List>
                <Accordion className={classes.drawerAccordion}>
                    <AccordionSummary
                        expandIcon={
                            <ExpandMore style={{ color: "#fff", fontSize: "15px" }} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h5">{t("game_cards")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.drawerAccordionDetails}>
                        <List>
                            {categori && (
                                <>
                                    {categori?.categories?.map((item) => (
                                        // <span>{item.name}</span>
                                        <ListItem key={`${item.id}_catagoryName`}>
                                            <Link
                                                className={classes.dropdown_link}
                                                to={`/categories/${item.slug}`}
                                            >
                                                {item.name}
                                            </Link>
                                            {/* <span>{item.name}</span> */}
                                        </ListItem>
                                    ))}
                                </>
                            )}
                        </List>
                    </AccordionDetails>
                </Accordion>
                 {categori?.pages?.map((item, i) => (
                    <ListItem key={i} className={classes.other_List_bg}>
                        <Link className={classes.text} to={`/page/${item.slug}`}>
                            {item?.title}
                        </Link>
                    </ListItem>
                ))}
                {/*<Accordion className={classes.drawerAccordion}>*/}
                {/*    <AccordionSummary*/}
                {/*        expandIcon={*/}
                {/*            <ExpandMore style={{ color: "#fff", fontSize: "15px" }} />*/}
                {/*        }*/}
                {/*    >*/}
                {/*        <Typography variant="h5"> {t("support")}</Typography>*/}
                {/*    </AccordionSummary>*/}
                {/*    <AccordionDetails className={classes.drawerAccordionDetails}>*/}
                {/*        <List>*/}
                {/*            <ListItem>*/}
                {/*                <Link className={classes.dropdown_link} to="#">*/}
                {/*                    {" "}*/}
                {/*                    پیشتیبانی 24 ساعته*/}
                {/*                </Link>*/}
                {/*            </ListItem>*/}
                {/*            <ListItem to="#">*/}
                {/*                <Link className={classes.dropdown_link} to="#">*/}
                {/*                    پرداخت با زرین پال*/}
                {/*                </Link>*/}
                {/*            </ListItem>*/}
                {/*            <ListItem to="#">*/}
                {/*                <Link className={classes.dropdown_link} to="#">*/}
                {/*                    شرایط و ضوابط*/}
                {/*                </Link>*/}
                {/*            </ListItem>*/}
                {/*        </List>*/}
                {/*    </AccordionDetails>*/}
                {/*</Accordion>*/}
                {/*<Accordion className={classes.drawerAccordion}>*/}
                {/*    <AccordionSummary*/}
                {/*        expandIcon={*/}
                {/*            <ExpandMore style={{ color: "#fff", fontSize: "15px" }} />*/}
                {/*        }*/}
                {/*    >*/}
                {/*        <Typography variant="h5"> {t("faq")}</Typography>*/}
                {/*    </AccordionSummary>*/}
                {/*    <AccordionDetails className={classes.drawerAccordionDetails}>*/}
                {/*        <List>*/}
                {/*            <ListItem>*/}
                {/*                <Link className={classes.dropdown_link} to="#">*/}
                {/*                    {" "}*/}
                {/*                    کارت هدیه چیست*/}
                {/*                </Link>*/}
                {/*            </ListItem>*/}
                {/*            <ListItem to="#">*/}
                {/*                <Link className={classes.dropdown_link} to="#">*/}
                {/*                    {" "}*/}
                {/*                    ایمیل بین المللی*/}
                {/*                </Link>*/}
                {/*            </ListItem>*/}
                {/*            <ListItem to="#">*/}
                {/*                <Link className={classes.dropdown_link} to="#">*/}
                {/*                    {" "}*/}
                {/*                    چگونه اکانت بسازیم*/}
                {/*                </Link>*/}
                {/*            </ListItem>*/}
                {/*        </List>*/}
                {/*    </AccordionDetails>*/}
                {/*</Accordion>*/}
            </List>
            <Divider />
            <LanguageModal />
        </div>
    );

    return (
        <div>
            {[dir].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button className="" onClick={toggleDrawer(anchor, true)}>
                        {children}
                    </Button>
                    <Drawer
                        classes={{ paper: classes.paper }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
