import React, {useEffect, useState} from "react";
import PageTitle from "../PageTitle/PageTitle";
import ReviewSlider from "./ReviewSlider";
import { useMediaQuery } from 'react-responsive'
import {useTranslation} from "react-i18next";


const Reviews = () => {
const {t} = useTranslation()
    const [deviceType,setDeviceType] = useState()
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    })
    const isMobile = useMediaQuery({
        query:'(max_width:600px)'
    })
 const getDeviceType = ()=>{
        if (isDesktopOrLaptop)setDeviceType('desktop')
        else if (isMobile) setDeviceType('mobile')
     else if(!isMobile&&!isDesktopOrLaptop)setDeviceType('tablet')
 }
 useEffect(()=>{
     getDeviceType()
 },[isMobile,isDesktopOrLaptop])
  return (
    <>
      <PageTitle title={t("reviews")} />


      <ReviewSlider deviceType={deviceType} />
    </>
  );
};

export default Reviews;
