import React from "react";
import { Grid, Box, Button, Typography } from "@material-ui/core";
import {useTranslation} from "react-i18next";
import egifts from "../../Assets/images/egifts-for-family-friends@2x.png";
import wholesaler from "../../Assets/images/wholesaler-prices-bulk@2x.png";
const Info = () => {
    const {t} = useTranslation()

  return (
    <div>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Box className='bg_Bulk'>
            <Box component='div' className='Bulk_head'>
              <Typography variant='h3' component='h3'>
                {t("wholesalers_welcome")}!
              </Typography>
              <img src={wholesaler} alt='' />
            </Box>
            <Box component='div' className='Info_Body'>
              <Box className='info_left_side'>
                <Typography component='p' variant='h5'>
                  {t("info_Wholesalers_p")}
                </Typography>
              </Box>
              <Box className='info_right_side'>
                <Typography component='p'>$$$</Typography>
                <Typography component='span'>{t("buy_in_bulk")}</Typography>
                <Button variant='contained' color='primary' size='large'>
                  {t("more_info")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box className='bg_Sage'>
            <Box component='div' className='Bulk_head'>
              <Typography variant='h3' component='h3'>
                {t("eGifts_for_friends_&_family")}              </Typography>
              <img src={egifts} alt='' />
            </Box>
            <Box component='div' className='Info_Body'>
              <Box className='info_left_side'>
                <Typography component='p' variant='h5'>
                  {t("info_freind_&_family_p")}
                </Typography>
               
              </Box>
              <Box className='info_right_side'>
                <Typography component='p'>100%</Typography>
                <Typography component='span'>{t("safe_&_secure")}</Typography>
                <Button variant='contained' color='primary' size='large'>
                  {t("more_info")}                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Info;
