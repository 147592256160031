import React from "react";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {makeStyles} from "@material-ui/core";
import EnFlag from "../../Assets/images/1200px-Flag_of_the_United_Kingdom.svg.png"
import IrFlag from "../../Assets/images/1200px-Flag_of_Iran_(official).svg.png"

const languageMap = {
    fa: {label: "فارسی", active: true},
    en: {label: "English", active: false}

};

const useStyles = makeStyles((theme) => ({
    btnLang: {
        border: "1px solid #fff",
        color: "#fff",
        fontSize: "13PX"
    }
}))

const LanguageSelect = ({userInfo}) => {
    const {t} = useTranslation();
    const [menuAnchor, setMenuAnchor] = React.useState(null);


    const classes = useStyles();
    return (
        <div className={userInfo ? "language-select-root  font-weight-bold mt-3" : "language-select-root  font-weight-bold"}>
            <Button className={classes.btnLang} onClick={({currentTarget}) => setMenuAnchor(currentTarget)}
            >
                {t("selected_language")}
                <ArrowDropDown fontSize="medium"/>
            </Button>
            <Popover
                open={!!menuAnchor}
                anchorEl={menuAnchor}
                onClose={() => setMenuAnchor(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <div>
                    <List>
                        {/*<ListSubheader>{t("select_language")}</ListSubheader>*/}
                        {Object.keys(languageMap)?.map(item => (
                            <ListItem
                                button
                                key={item}
                                onClick={() => {
                                    i18next.changeLanguage(item);
                                    setMenuAnchor(null);
                                }}
                                className='font-weight-bold'
                            >
                                <img className={"mr-1 ml-1"} height={"17px"} src={item === "fa" ? IrFlag : EnFlag}
                                     alt={"flag "}/>
                                {languageMap[item].label}
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Popover>
        </div>
    );
};

export default LanguageSelect;