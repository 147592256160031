import {useEffect} from "react";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import {Container} from "@material-ui/core";
import NavHeader from "./nav/NavHeader";
import MobileHeader from "./header/MobileHeader";
import useMediaQuery from "@material-ui/core/useMediaQuery"

const DefaultLayout = ({children}) => {
    const matches = useMediaQuery('(min-width:1024px)');
    return (
        <div className="Gird_Container">
            <header className='w-100 '>
                {matches ? (
                    <>
                        <Header/>
                        <NavHeader/>
                    </>
                ) : (
                    <>
                        <MobileHeader/>
                    </>
                )}
            </header>
            <main>
                <Container maxWidth="lg" className='p-0'>{children}</Container>
            </main>
            <footer>
                <Footer/>
            </footer>
        </div>
    );
};

export default DefaultLayout;
