import React from 'react';
import i18next from "i18next";
import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Divider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const LanguageModal = () => {
    const {t} = useTranslation();
    const useStyles = makeStyles((theme) => ({
        SelectLang: {
            marginRight: t('direction') === "rtl" ? 15 : "unset",
            marginLeft: t('direction') === "ltr" ? 15 : "unset",
            textAlign: t('direction') === "rtl" ? "right" : "left",
            direction: t('direction') === "rtl" ? "rtl" : "ltr",
        },
        root: {
            "&>legend": {
                color: "#fff",
                fontSize: 12,
            },
            input: {
                width: "40px",

            },
            "&>span": {
                fontSize: 12,
            }
        },
        formLabel: {
            "&>span": {
                fontSize: 15,
            }
        }
    }));
    const classes = useStyles()
    return (
        <div className={classes.SelectLang}>
            <FormControl component="fieldset" className={classes.root}>
                <FormLabel component="legend">{t('Setting_MENU')}</FormLabel>
                <RadioGroup aria-label="language" name="language">
                    <FormControlLabel
                        className={classes.formLabel}
                        onClick={() => {
                            i18next.changeLanguage("fa");
                        }}
                        control={<Radio/>} label="fa"/>
                    <Divider className={"mt-2 mb-2 w-100"}/>
                    <FormControlLabel
                        className={classes.formLabel}
                        onClick={() => {
                            i18next.changeLanguage("en");
                        }}
                        control={<Radio/>} label="en"/>
                </RadioGroup>
            </FormControl>
        </div>
    );
};
export default LanguageModal;




