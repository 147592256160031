export default function parseJwt(token) {
    if (!token) {
        localStorage.removeItem("userInfo");
        window.location.href = "/login"
    }
    const base64Url = token.split(".");
    if (base64Url.length > 1) {
        const base64 = base64Url[1].replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    } else {
        localStorage.removeItem("userInfo");
        window.location.href = "/login"
    }

}