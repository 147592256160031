import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Menu, MenuItem} from '@material-ui/core';
import {ArrowDropDown, ArrowDropUp} from '@material-ui/icons';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next"

export default function MultipleSelect({categori}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const {t} = useTranslation();

    const useStyles = makeStyles((theme) => ({
        root: {
            fontSize: "13px !important",
            "& ul": {
                backgroundColor: " #ca1d1d",
                minWidth: 200,
                direction: t('direction') === "ltr" ? "ltr" : "rtl",
                "&>  li": {
                    fontSize: "14px",
                    color: "#fff"
                }
            }
        },
        menuItem: {},
        open_menu: {
            color: "#fff",
            fontSize: "13px",
            marginTop: "8px"
        },
        icon_category: {
            fontSize: "22px"
        }
    }));

    const classes = useStyles();


    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" className={classes.open_menu}
                    onClick={handleClick}>
                {t('game_cards')}
                {anchorEl === null ? <ArrowDropDown className={classes.icon_category}/> :
                    <ArrowDropUp className={classes.icon_category}/>}

            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                className={classes.root}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {categori?.categories?.map((item, i) => (
                    <MenuItem
                        key={i}
                        onClick={() => handleClose()}
                        value={item.name}
                        className={"menuItem"}>
                        <div onClick={() => history.push(`/categories/${item.slug}`)}>
                            {item.name}
                        </div>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
