import { _http } from "../../helper/httpServices";
import { categories } from "../constant/categori-constant";
import { ERROR } from "../../feature/alerts";

///get All Categories
export const gteAllCategories = () => async (dispatch) => {
  dispatch({ type: categories.GET_ALL_CATEGORIES_REQUSET });
  try {
    const res = await _http("categories", { method: "get" });

    dispatch({
      type: categories.GET_ALL_CATEGORIES_SUCCESS,
      payload: res?.data?.data,
    });
  } catch (error) {
    let message;
    if (error?.response?.data?.message)
      message = error?.response?.data?.message;
    else message = error?.message;
    ERROR(message);
    dispatch({ type: categories.GET_ALL_CATEGORIES_FALI, payload: message });
  }
};

///get getegori product
export const gteCategoriesProduct = (slug) => async (dispatch) => {
  dispatch({
    type: categories.GET_CATEGORIES_PRODUCT_REQUSET,
    payload: slug,
  });
  try {
    const res = await _http(`categories/${slug}`, { method: "get" });
    dispatch({
      type: categories.GET_CATEGORIES_PRODUCT_SUCCESS,
      payload: res?.data,
    });
  } catch (error) {
    let message;
    if (error?.response?.data?.message)
      message = error?.response?.data?.message;
    else message = error?.message;
    ERROR(message);
    dispatch({
      type: categories.GET_CATEGORIES_PRODUCT_FALI,
      payload: message,
    });
  }
};
