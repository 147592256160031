import React, {useEffect, useState} from "react";

import {Box, Button, Grid, List, ListItem, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {AddShoppingCart} from "@material-ui/icons";
import {history} from "../../Router/history";
import {useSelector, useDispatch} from "react-redux";
import {addToCart, updateCart} from "../../redux/actions/cart-action";
import numberWithCommas from "../../helper/numberWithCommas";
import {useTranslation} from "react-i18next";
import {VariantType, useSnackbar} from 'notistack';
import {toPersianNum} from "../../helper/ToPersionNumber"

function SingleGridCard(props) {
    const {enqueueSnackbar} = useSnackbar();
    const [snack, setSnackBar] = useState(false)

    const handleClicks = (variant) => {
        enqueueSnackbar('This is a success message!', {variant});

    }
    const {item} = props

    const dispatch = useDispatch()

    const cardList = useSelector((state) => state.cartData.cardList);

    const {t} = useTranslation();

    const addCart = (item, variant) => {
        let fa = t('direction') === "rtl" ? `${item?.name} ${t("Cart_SUCCESS_ADD")}` : `${item?.name} ${t('Cart_SUCCESS_ADD')}`;
        enqueueSnackbar(fa, {variant});

        if (cardList) {
            const index = cardList.findIndex((i) => i.id === item.id)
            if (index < 0) {
                dispatch(addToCart({...item, card_num: 1,amount:item.min_price}));
            } else {
                const cardNum = cardList[index].card_num;

                dispatch(updateCart(item.id, {card_num: cardNum + 1}))
            }
        } else {
            dispatch(addToCart({...item, card_num: 1}));
        }
    };


    const handleBuyClick = (variant) => {
        let addItem = t('direction') === "rtl" ? `${item?.name} ${t("Cart_SUCCESS_ADD")}` : `${item?.name} ${t('Cart_SUCCESS_ADD')}`;
        enqueueSnackbar(addItem, {variant});
        if (cardList) {
            const index = cardList.findIndex((i) => i.id === item.id)

            if (index < 0) {
                dispatch(addToCart({...item, card_num: 1,amount:item.min_price}));
            } else {
                const cardNum = cardList[index].card_num;
                dispatch(updateCart(item.id, {card_num: cardNum + 1}))
            }
        } else {

            dispatch(addToCart({...item, card_num: 1,amount:item.min_price}));
        }
        history.push(`/checkout`)
    }
    return (
        <Grid item lg={2} md={3} sm={6} xs={6} key={`
        giftCart_$
        {
            item.id
        }
        `}>
            <Box className="Card_media Cart_Game_head">
                <Link
                    to={{
                        pathname: `/products/${item.slug}`,
                        state: {item},
                    }}
                >
                    <img className="Card_img" src={item.photo} alt=""/>
                </Link>
            </Box>
            <List>
                <ListItem className="Cart_list_items">
                    <Typography variant="h5" component="h3">
                        <Link className="Cart_link" to={{
                            pathname: `/products/${item.slug}`,
                            state: {item},
                        }}>{item.name}</Link>
                    </Typography>
                </ListItem>
                <ListItem className="Cart_list_items btn">
                {item.qty !== 0?<div className="cartBtn ">
                    <div className="cartBtn_cartIconNum">
                            <Button
                                disabled={item.qty === 0}
                                onClick={() => addCart(item, "success")}
                                className={`
        btn_add_Cart  ${t("direction") === "rtl" ? "mr-4" : "ml-4"}`}
                            >
                                <AddShoppingCart style={{color: '#af1800'}}/>

                            </Button>
                            <span className='cardNum' style={t("direction") !== "rtl" ? {right: '-10px'} : {}}>{(() => {
                                const index = cardList?.findIndex(i => i.id === item.id)
                                const cardNum = index > -1 ? cardList[index].card_num : 0
                                return cardNum
                            })()}</span>
                        </div>
                        <Button
                            disabled={item.qty === 0}
                            className="btn_buy"
                            variant="contained"
                            color="primary"
                            onClick={() => handleBuyClick('success')}
                        >
                            {item.qty === 0 ? t("OUT_OF_STOCK") : t("buy")}
                        </Button>
                    </div>:t("OUT_OF_STOCK")}
                </ListItem>

                <ListItem className={t('direction') === "rtl"?"text-right Cart_list_items price":"Cart_list_items price"}>
                    {item.selling_price.price!==item.selling_price.off_price&&<Box className="orginal price_size">
                        <Typography component="span" variant="h5">
                            {t("original")}
                        </Typography>
                        <Typography  component="p" variant="h5">
                            {t('direction') === "rtl" ? toPersianNum(item.selling_price.price) : numberWithCommas(item.selling_price.price)}
                            {item.selling_price.currency_name}
                        </Typography>
                    </Box>}
                    <Box className="now price_size">
                        <Typography component="span" variant="h5">
                            {t("now")}:
                        </Typography>
                        <Typography component="p" variant="h5">
                            {t('direction') === "rtl" ? toPersianNum(item.selling_price.off_price) : numberWithCommas(item.selling_price.off_price)}
                            {item.selling_price.currency_name}
                        </Typography>
                    </Box>
                </ListItem>
                <ListItem>
                </ListItem>
            </List>
        </Grid>
    )
}

export default SingleGridCard