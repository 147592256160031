import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Box} from "@material-ui/core";
import {useSelector} from "react-redux";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};


// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const ReviewSlider = ({deviceType}) => {
    const homeData = useSelector((state)=>state.homeData)
    return (

       <>
           {homeData?.home?.data?.reviews &&  <Carousel
               deviceType={deviceType}
               itemClass="Reviews_review"
               responsive={responsive}
               autoPlay={true}
               infinite={true}
               containerClass="carousel-container"
                arrows={true}
               autoPlaySpeed={3000}

           >
               {homeData?.home?.data?.reviews?.map(r=>
                   <Box  boxShadow={2} style={{
                       minHeight: "300px"}} className=' d-flex flex-column align-content-center Cart_Game_head'    key={`reviewH-${r.id}`}>
                       <h3 className="text-center py-4">

                       {r.title}
                       </h3>
                       <p className="text-right text-secondary p-3">{r.review}</p>
                       <p className=' font-italic text-center mt-auto w-100'>{r.created_at.substring(0,10)}</p>
                   </Box>

               )}


           </Carousel>}

           </>
    );
};

export default ReviewSlider;