import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import {AccountCircle} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {ListItem, List, IconButton, Avatar} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {CEHCkOUT, PROFILE} from "../../constant/Router";
import {logout} from "../../redux/actions/auth-actions";
import parseJwt from "../../helper/decode";
import {useTranslation} from "react-i18next";
import LanguageSelect from "../../components/LanguageSelect/LanguageSelect";

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    Link_profile: {
        color: "#333",
    },
    Avatar__Name: {
        backgroundColor: "#ca1d1d",
        color: "#fff",
        marginTop: "-17px",
    },
    signOut: {
        cursor: "pointer",
        borderTop: "1px solid #dee2e6 ",
    },
    user__Link: {
        textDecoration: "none",
    },
    userProfile_list: {
        "&> li > a": {
            textTransform: "capitalize",
            fontSize: "14px"
        },
        "&> li >div": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    iconSize:{
        fontSize:19
    }
}));
export default function UserPofile({signoutHandler}) {
    const [saveToken, setSaveToken] = useState("");
    const auth = useSelector((state) => state.auth);
    const {userInfo, loading} = auth;

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {t} = useTranslation()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const dispatch = useDispatch();
    const handelLogOut = () => {
        dispatch(logout());
        setAnchorEl(null);
    };

    useEffect(() => {
        const userToken = localStorage.getItem("userInfo");
        const decode = parseJwt(userToken);
        setSaveToken(decode);
    }, []);

    return (
        <>
            <IconButton
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={handleClick}
                className='mt-4'
            >
                <Avatar color="primary" className={classes.Avatar__Name}>
                    <AccountCircle className={classes.iconSize}/>
                </Avatar>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <List className={classes.userProfile_list} dir={t("direction")}>
                    {userInfo?.isAdmin ? (
                        <>

                            <ListItem>Welcome {userInfo.name}</ListItem>
                            <ListItem className={classes.signOut} onClick={handelLogOut}>
                                SignOut
                            </ListItem>
                            <ListItem>
                                <Link
                                    onClick={handleClose}
                                    className={classes.user__Link}
                                    to="/dashboard"
                                >
                                    Dashboard
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link
                                    onClick={handleClose}
                                    className={classes.user__Link}
                                    to="/cart"
                                >
                                    Cart
                                </Link>
                            </ListItem>
                        </>
                    ) : (
                        <>
                            <ListItem className={`border-bottom d-flex align-items-center`}>
                                <div className="">
                                    <p>{saveToken?.email}</p>
                                </div>
                            </ListItem>
                            <ListItem>
                                <Link
                                    onClick={handleClose}
                                    className={classes.Link_profile}
                                    to={CEHCkOUT}
                                >
                                    {t("cart")}
                                </Link>{" "}
                            </ListItem>
                            <ListItem>
                                <Link
                                    onClick={handleClose}
                                    className={classes.Link_profile}
                                    to={PROFILE}
                                >
                                    {t("profile")}
                                </Link>{" "}
                            </ListItem>
                            <ListItem className={classes.signOut} onClick={handelLogOut}>
                                {t("logout")}
                            </ListItem>
                        </>
                    )}
                </List>
            </Popover>
        </>
    );
}
