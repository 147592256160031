import React from "react";
import { Typography } from "@material-ui/core";

const PageTitle = ({ variant, title, color, ...rest }) => {
  return (
    <>
      <div className='Page_Titl_xl my-2 text-uppercase'>
        <div className='Page_Title_Heade'>
          <Typography className='Title_Size' variant {...rest}>
            {title}
          </Typography>
        </div>
      </div>
      <div className='Page_Titl_lg'>
        <div className='Page_Title_Heade'>
          <br />
          <Typography component='h3' className='Title_Size' variant {...rest}>
            {title}
          </Typography>
          <br />
        </div>
      </div>
    </>
  );
};

export default PageTitle;
