import React from "react";
import {Box, Typography, Grid, List, ListItem} from "@material-ui/core";
import {Link} from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import {useTranslation} from "react-i18next";
import {
    CreditCard,
    PlayCircleFilledWhite,
    CardGiftcard,
    CallReceived,
} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core";
// import { getHomeData } from "../../redux/actions/home-actions";
const useStyles = makeStyles((theme) => ({
    GifT_Gallery: {
        display: "grid",
        gridGap: "35px",
        gridAutoRows: "150px",
        gridAutoFlow: "row dense",
        transition: " 0.5s ease-in-out",
        justifyItems: "center",
        padding: "40px  0",
    },
    Gif_Container: {
        marginTop: "15px",
    },
}));
const GameCart = ({homeData}) => {
    const {home, loading} = homeData;

    const {t} = useTranslation()

    const classes = useStyles();


    return (
        <div className="Game_Cart">
            <Box component="div" className={classes.Gif_Container}>
                <Box component="div" className={`${classes.GifT_Gallery} GifT_Gallery`}>
                    {loading ? (
                        <>
                            {[1, 2, 3, 4, 5, 6].map((item, i) => (
                                <Skeleton key={i} variant="rect" width={171} height={132}/>
                            ))}
                        </>
                    ) : (
                        home?.data?.main_category?.map((item, i) => (
                            <Link
                                key={i}
                                to={`/categories/${item.slug}`}
                                className={classes.Gif_Hover}
                            >
                                <img src={item.photo} alt={item.name} className={"Gif_Hover"}/>
                            </Link>
                        ))
                    )}
                </Box>
                <Typography className="Title_cart_head " variant="h1" component="h3">
                    {t("title_GameCart")}
                </Typography>
                <Typography className="Title_cart font-italic" variant="h2" component="h3">
                    {t("buy_now")}. {t("play_now")}.
                </Typography>
                <Box className="carts">
                    <Grid container spacing={2}>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography className="cart_Heade" variant="h3">
                                <Box className="circle_Icon">
                                    <CreditCard/>
                                </Box>
                                <Link to={"/"}>{t("game_cards")}</Link>
                            </Typography>
                            <Typography className="cart_Info" component="p" variant="h4">
                                {t("game_cards_p")}
                            </Typography>
                            <List className="Cart_list">
                                <ListItem>
                                    <PlayCircleFilledWhite className="icon_bg play"/>
                                    <Link  to={"/categories/playstation"}>{t("playstation_game_cards")}</Link>
                                </ListItem>
                                <ListItem>
                                    <PlayCircleFilledWhite className="icon_bg Steam"/>

                                    <Link to={`/categories/steam`}>

                                        {t("steam_game_cards")}
                                    </Link>
                                </ListItem>

                            </List>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography className="cart_Heade" variant="h3">
                                <Box className="circle_Icon">
                                    <CardGiftcard/>
                                </Box>
                                <Link  to={"/"}>{t("gift_cards")}</Link>
                            </Typography>
                            <Typography className="cart_Info" component="p" variant="h4">
                                {t("gift_cards_p")}
                            </Typography>
                            <List className="Cart_list">
                                <ListItem>
                                    <PlayCircleFilledWhite className="icon_bg play"/>
                                    <Link  to={"/categories/playstation"}>{t("playstation_game_cards")}</Link>
                                </ListItem>
                                <ListItem>
                                    <PlayCircleFilledWhite className="icon_bg Steam"/>

                                    <Link  to={"/categories/steam"}>

                                        {t("steam_game_cards")}
                                    </Link>
                                </ListItem>

                            </List>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography className="cart_Heade" variant="h3">
                                <Box className="circle_Icon">
                                    <CallReceived/>
                                </Box>
                                <Link  to={"/"}>{t("instant_delivery")}</Link>
                            </Typography>
                            <Typography className="cart_Info" component="p" variant="h4">
                                {t("instant_delivery_p")}
                            </Typography>
                            <List className="Cart_list">
                                <ListItem>
                                    <PlayCircleFilledWhite className="icon_bg play"/>
                                    <Link  to={"/categories/playstation"}>{t("playstation_game_cards")}</Link>
                                </ListItem>
                                <ListItem>
                                    <PlayCircleFilledWhite className="icon_bg Steam"/>
                                    <Link  to={"/categories/steam"}>
                                        {t("steam_game_cards")}
                                    </Link>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
};

export default GameCart;
